import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import the useNavigate hook
import './Withdraw.css'; 
import CustomAlert from './CustomAlert';

export default function Withdraw() {
  const [balance, setBalance] = useState(0);  // Store user's balance
  const [amount, setAmount] = useState('');   // Input value for withdrawal amount
  const [walletAddress, setWalletAddress] = useState('');  // Wallet address fetched from database
  const [totalWithdrawal, setTotalWithdrawal] = useState(0); // Store user's total withdrawals
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };

  const navigate = useNavigate();
  const username = localStorage.getItem('username');
  const [method] = useState('BNB'); // Default withdrawal method
  
  const goBack = () => {
    navigate('/softie');
  };
  
  const historyPage = () => {
    navigate('/history'); // Navigate back to the /softie page
  };

  // Redirect after a delay
  const redirectAfterDelay = (path, delay = 5000) => {
    setTimeout(() => {
      navigate(path);
    }, delay);
  };

  // Fetch the wallet address, earning balance, and total withdrawal when the component mounts
  useEffect(() => {
    fetch(`https://softcoin-axlm.onrender.com/api/walletAddress/${username}`)
      .then(response => response.json())
      .then(data => {
        if (data.error || !data.walletAddress) {
          // If the wallet address is empty or there's an error, alert the user and redirect
          showCustomAlert('Please connect your wallet.');
          redirectAfterDelay('/connect-wallet');
        } else {
          setWalletAddress(data.walletAddress);
        }
      })
      .catch(error => {
        console.error('Error fetching wallet address:', error);
        showCustomAlert('Error fetching wallet address.');
      });

    fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/earningBalance`)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          showCustomAlert('Error fetching earning balance.');
        } else {
          setBalance(Math.floor(data.earningBalance));  // Round balance to nearest integer
        }
      })
      .catch(error => {
        console.error('Error fetching earning balance:', error);
        showCustomAlert('Error fetching earning balance.');
      });

    // Fetch total withdrawal amount
    fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/withdrawals`)
      .then(response => response.json())
      .then(data => {
        if (data.error) {
          showCustomAlert('Error fetching withdrawal data.');
        } else {
          setTotalWithdrawal(data.totalWithdrawal || 0);  // Set the total withdrawal
        }
      })
      .catch(error => {
        console.error('Error fetching withdrawal data:', error);
        showCustomAlert('Error fetching withdrawal data.');
      });
  }, [username]);

  // Handle "Use Max" button click
  const handleUseMax = () => {
    setAmount(balance);
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate input fields
    if (amount < 10) {
      showCustomAlert('Minimum Withdrawal is 10 USD.');
      return;
    }

    if (!walletAddress.trim()) {
      showCustomAlert('Wallet address is not available.');
      return;
    }

    // Display loading alert
    showCustomAlert('Processing your request...', 'loading');

    // Fetch both earning balance and commitment balance
    Promise.all([
      fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/earningBalance`).then(response => response.json()),
      fetch(`https://softcoin-axlm.onrender.com/api/users/${username}/commitmentBalance`).then(response => response.json())
    ])
    .then(([earningData, commitmentData]) => {
      if (earningData.error || commitmentData.error) {
        showCustomAlert('Error fetching balances.');
        return;
      }

      if (amount > earningData.earningBalance) {
        showCustomAlert('The amount exceeds your earning balance.');
      } else if (commitmentData.commitmentBalance <= 0) {
        showCustomAlert('Your commitment balance must be greater than 0 to withdraw.');
      } else {
        // Submit withdrawal request
        fetch('https://softcoin-axlm.onrender.com/api/withdraw', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({ username, amount, method, walletAddress })
        })
        .then(response => response.json())
        .then(data => {
          if (data.success) {
            showCustomAlert('Withdrawal request submitted successfully.', 'success');
            redirectAfterDelay('/softie'); // Redirect after 5 seconds
          } else {
            showCustomAlert('Withdrawal request submitted successfully.', 'success');
            redirectAfterDelay('/softie');
          }
        })
        .catch(error => {
          console.error('Error submitting withdrawal request:', error);
          showCustomAlert('Failed to submit withdrawal request.');
        });
      }
    })
    .catch(error => {
      console.error('Error fetching balances:', error);
      showCustomAlert('Error fetching balances.');
    });
  };

  const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
  };

  return (
    <div className="withdraw app">
      <h2 style={{ marginTop: '33px', fontSize: '30px'}}>Cash Out</h2>
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>

      <h3 className="wallet-b">Balance: <span>${balance.toFixed(0)}</span></h3>

      <p>Enter Amount (USD)</p>
      <input
        type="number"
        placeholder="0"
        value={amount}
        onChange={(e) => setAmount(e.target.value)}
        style={{
          width: '90%', padding: '0', height: '150px', textAlign: 'center',
          fontSize: '60px', margin: '5px auto', background: 'none', color: 'white', marginTop: '5px', border: 'none', outline: 'none'
        }}
      />

      <button
        style={{ fontSize: '10px', width: '20%', padding: '2px', margin: '5px auto' }}
        className="submit-button"
        onClick={handleUseMax}
        type="button"
      >
        Use Max
      </button>

      <button
        style={{ width: '50%', margin: '30px auto' }}
        className="submit-button"
        onClick={handleSubmit}
        type="submit"
      >
        Withdraw
      </button>

      <p style={{ textAlign: 'center' }} className="note">NOTE:</p>
      <p className="note">10 percent of the withdrawn amount will be deducted for withdrawal processing.</p>
      <p className="note">Transaction will be carried out strictly with BNB (BEP-20), ensure the wallet address you connected is accurate.</p>

      <h4 className="cashed">Cashed Out: <span>${totalWithdrawal.toFixed(0)}</span></h4> {/* Updated to display totalWithdrawal */}
      <a style={{ textDecoration: 'none', width: '60%', margin: '30px auto'}} className="history" onClick={historyPage}>View Transaction History</a>

      {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
