import React, { useState } from 'react';
import { useNavigate, Routes, Route } from 'react-router-dom';
import './Login.css';

function Login() {
  const [usernameEmail, setUsernameEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [isVerified, setIsVerified] = useState(true);

  const navigate = useNavigate();
  
  const resendVerificationPage = () => {
    navigate('/resend-verification');
  };
  
  const registerPage = () => {
    navigate('/register');
  };
  
  const resetPasswordPage = () => {
    navigate('/reset-password');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const loginData = { usernameEmail, password };
    setLoading(true);

    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/auth/login', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      });

      const result = await response.json();
      setLoading(false);
      setAlertMessage(result.message);
      setAlertType(response.ok ? 'success' : 'danger');

      if (result.isVerified === false) {
        setIsVerified(false);
      }

      if (response.ok) {
        localStorage.setItem('token', result.token);
        localStorage.setItem('username', result.username);
        navigate('/home');
      }
    } catch {
      // If the network is offline, attempt to register background sync
      if ('serviceWorker' in navigator && 'SyncManager' in window) {
        navigator.serviceWorker.ready.then(function(swRegistration) {
          swRegistration.sync.register('sync-login-data').then(() => {
            console.log('Login data will be synced when online.');
            setLoading(false);
            setAlertMessage('You are offline. Your login will be synced when the network is available.');
            setAlertType('info');
          }).catch(() => {
            console.error('Failed to register background sync.');
            setLoading(false);
            setAlertMessage('An error occurred. Please try again.');
            setAlertType('danger');
          });
        });
      } else {
        setLoading(false);
        setAlertMessage('An error occurred. Please try again.');
        setAlertType('danger');
      }
    }
  };

  return (
    <div className="form-body app">
      
      <div className="login-container">
      <div className="logo-container">
        <img src="transparent-logo2/10.png" alt="Softcoin Logo" className="logo" />
      </div>
        <form id="login-form" onSubmit={handleSubmit}>
          <h1>Log In</h1>
          <div id="alert-box" className={`custom-alert ${alertType ? `alert-${alertType}` : ''}`} style={{ display: alertMessage ? 'block' : 'none' }}>
            {alertMessage}
          </div>
          <input
            type="text"
            id="username-email"
            placeholder="Email or Username"
            value={usernameEmail}
            onChange={(e) => setUsernameEmail(e.target.value)}
            required
          />
          <div className="password-container">
            <input
              type={showPassword ? 'text' : 'password'}
              id="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <span id="toggle-password" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? 'Hide' : 'Show'}
            </span>
          </div>
          <button className="submit-button" type="submit" disabled={loading}>{loading ? 'Logging in...' : 'Login'}</button>
          {!isVerified && (
            <p id="resend-verification">
              <a onClick={resendVerificationPage}>Resend Verification Email</a>
            </p>
          )}
          <p>Don't have an account? Register <a onClick={registerPage}>here</a></p>
          <p><a onClick={resetPasswordPage}>Forgot Password?</a></p>
        </form>
        <div className="verify">
          <p>If your email verification link expired and you need a new verification link sent to you, try logging-in first. The system will detect that your email is yet to be verified and a "Resend verification email" will appear below the form. click on the link and follow the prompt to resend verification link.</p>
        </div>
      </div>
    </div>
  );
}

export default Login;
