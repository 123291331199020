import { useState, useEffect } from 'react';
import './Upgrade.css';
import CustomAlert from './CustomAlert';
import { useNavigate, Routes, Route } from 'react-router-dom';

export default function Upgrade() {
  const [currentLevel, setCurrentLevel] = useState(null);
  const [username, setUsername] = useState(localStorage.getItem('username'));
  const [alertMessage, setAlertMessage] = useState(null); // Manage alert state

  const closeAlert = () => {
    setAlertMessage(null); // Reset alertMessage to hide the alert
  };
  const navigate = useNavigate();
  
  const goBack = () => {
    navigate('/home');
  };

  useEffect(() => {
    const fetchMiningStatus = async () => {
      try {
        const response = await fetch('https://softcoin-axlm.onrender.com/api/miningStatus', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ username }),
        });
        const data = await response.json();
        if (data) {
          setCurrentLevel(data.level);
        }
      } catch (error) {
        showCustomAlert('Failed to fetch user data');
      }
    };

    fetchMiningStatus();
  }, [username]);

  const upgradeLevel = async (level) => {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/upgradeLevel', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, level }),
      });
      const data = await response.json();
      if (data.success) {
        showCustomAlert(`Successfully upgraded to level ${level}!`, () => {
          navigate('/home');
        });
      } else {
        showCustomAlert(data.message);
      }
    } catch (error) {
      showCustomAlert('Failed to upgrade level');
    }
  };
  
const showCustomAlert = (message) => {
    setAlertMessage(message); // Set the custom alert message to trigger the alert
};
  const levels = [
    { level: 1, reward: '2,500', session: '2 Hours', cost: 'Default Level' },
    { level: 2, reward: '3300', session: '3 Hours', cost: '100,000 SFT' },
    { level: 3, reward: '5000', session: '4 Hours', cost: '500,000 SFT' },
    { level: 4, reward: '8000', session: '5 Hours', cost: '2,000,000 SFT' },
    { level: 5, reward: '13300', session: '6 Hours', cost: '10,000,000 SFT' },
  ];

  return (
    <div className="Upgrade app">
      <a onClick={goBack}>
        <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
      </a>
      <div id="upgrade-options">
        <p id="text">Unlocked bigger rewards and longer sessions with every upgrade. The goal is to make it to the top, and become the king you are born to be!</p>
        {levels.map(({ level, reward, session, cost }) => (
          <div key={level} className="level-option" data-level={level}>
            <h2>
              Level {level} {level === currentLevel && <div className="badge">Current Level <i style={{marginLeft: '5px', color: '#bda003'}} className="fas fa-bolt"></i></div>}
            </h2>
            <img src={`/upgrade/level${level}.png`} alt={`Level ${level}`} />
            <p>Earn <span style={{ color: '#48e4fa', fontWeight: 'bold'}}>{reward}</span> SFT Per Hour<br/>Check in every <span style={{ color: 'gold', fontWeight: 'bold'}}>{session}</span> to continue mining.</p>
            <h3>{cost}</h3>
            <button
              onClick={() => upgradeLevel(level)}
              disabled={level <= currentLevel || level !== currentLevel + 1}
            >
              {level === currentLevel ? 'Unlocked' : 'Upgrade to Level ' + level}
            </button>
          </div>
        ))}
      </div>
{/* Custom Alert */}
    {alertMessage && <CustomAlert message={alertMessage} onClose={closeAlert} />}
    </div>
  );
}
