import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Register.css'; // Optional: move your styles into a separate CSS file

function Register() {
  const [fullName, setFullName] = useState('');
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [referralUsername, setReferralUsername] = useState('');
  const [alertMessage, setAlertMessage] = useState('');
  const [alertType, setAlertType] = useState('');
  const [loading, setLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false); // State for password visibility
  const [showConfirmPassword, setShowConfirmPassword] = useState(false); // State for confirm password visibility

  const navigate = useNavigate(); // Initialize useNavigate
  
  const loginPage = () => {
    navigate('/login');
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const referrer = urlParams.get('ref');
    if (referrer) {
      setReferralUsername(referrer);
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setAlertMessage('Passwords do not match');
      setAlertType('danger');
      return;
    }

    const payload = { fullName, username, email, password, referralUsername };
    setLoading(true);

    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/auth/register', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload),
      });

      const data = await response.json();
      setLoading(false);

      if (response.ok) {
        setAlertMessage('Registration successful! Please check your email to verify your account.');
        setAlertType('success');
        setTimeout(() => {
          navigate('/login'); // Redirect to the login page after successful registration
        }, 5000);
      } else {
        setAlertMessage(data.message || 'Registration failed');
        setAlertType('danger');
      }
    } catch (error) {
      setLoading(false);
      setAlertMessage('An error occurred during registration');
      setAlertType('danger');
    }
  };

  return (
        <div className="form-body app">
    <div className="register-container">
      {/* Logo and "Softcoin" Title */}
     <div className="logo-container">
        <img src="transparent-logo2/10.png" alt="Softcoin Logo" className="logo" />
      </div>
      <form id="registration-form" onSubmit={handleSubmit}>

        <h1>Register</h1>
                <div id="alert-box" className={`custom-alert ${alertType ? `alert-${alertType}` : ''}`} style={{ display: alertMessage ? 'block' : 'none' }}>
          {alertMessage}
        </div>
        <input type="text" id="full-name" placeholder="Full Name" value={fullName} onChange={(e) => setFullName(e.target.value)} required />
        <input type="text" id="username" placeholder="Username" value={username} onChange={(e) => setUsername(e.target.value)} required />
        <input type="email" id="email" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} required />
        
        {/* Password Field */}
        <div className="password-container">
          <input
            type={showPassword ? 'text' : 'password'} // Toggles between text and password
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
          >
            {showPassword ? 'Hide' : 'Show'}
          </span>
        </div>

        {/* Confirm Password Field */}
        <div className="password-container">
          <input
            type={showConfirmPassword ? 'text' : 'password'} // Toggles between text and password
            id="confirm-password"
            placeholder="Confirm Password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
          <span
            className="toggle-password"
            onClick={() => setShowConfirmPassword(!showConfirmPassword)} // Toggle confirm password visibility
          >
            {showConfirmPassword ? 'Hide' : 'Show'}
          </span>
        </div>

        <input
          type="text"
          id="referral-username"
          placeholder="Referral Username (Optional)"
          value={referralUsername}
          onChange={(e) => setReferralUsername(e.target.value)}
        />
        <button className="submit-button" type="submit" disabled={loading}>{loading ? 'Registering...' : 'Register'}</button>
        <p>Already have an account? Log in <a onClick={loginPage}>here</a></p>
      </form>
    </div>
  </div>
  );
}

export default Register;
