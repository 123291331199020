import React from 'react';
import './SoundWaveLoader.css'; // Create a new CSS file for the animation styles

export function SoundWaveLoader() {
  return (
    <div style={{ marginRight: '-20px'}} className="sound-wave-loader">
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
      <div className="bar"></div>
    </div>
  );
}
