import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './CryptoMarket.css';
import { useNavigate, Routes, Route } from 'react-router-dom';

export default function CryptoMarket() {
  const [cryptoPrices, setCryptoPrices] = useState({});
  const [percentageChanges, setPercentageChanges] = useState({});
  const [highlightedPrices, setHighlightedPrices] = useState({});
  const [totalMarketCap, setTotalMarketCap] = useState(null);
const [totalVolume24h, setTotalVolume24h] = useState(null);
const [cryptoDetails, setCryptoDetails] = useState({});
const [expandedCrypto, setExpandedCrypto] = useState(null);
const navigate = useNavigate();

const goBack = () => {
    navigate('/more'); // Navigate back to the /softie page
  };

  
  useEffect(() => {
  async function fetchCryptoData() {
    try {
      const response = await fetch('https://softcoin-axlm.onrender.com/api/crypto-prices');
      const data = await response.json();

      const newPrices = data.currentPrices;
      const updatedHighlights = {};
      const newCryptoDetails = {};

      for (const [id, newPriceData] of Object.entries(newPrices)) {
        const prevPrice = cryptoPrices[id]?.usd;
        if (prevPrice && prevPrice !== newPriceData.usd) {
          updatedHighlights[id] = true;
        }
        
        // Collect market cap and volume data
        newCryptoDetails[id] = {
          marketCap: newPriceData.marketCap,
          volume24h: newPriceData.volume24h
        };
      }

      setCryptoPrices(newPrices);
      setPercentageChanges(data.percentageChanges);
      setHighlightedPrices(updatedHighlights);
      setCryptoDetails(newCryptoDetails);
      
      // Set total market cap and 24hr volume
      setTotalMarketCap(data.totalMarketCap);
      setTotalVolume24h(data.totalVolume24h);

      setTimeout(() => setHighlightedPrices({}), 1000);
    } catch (error) {
      console.error('Error fetching crypto data:', error);
    }
  }

  fetchCryptoData();
  const interval = setInterval(fetchCryptoData, 60000);
  return () => clearInterval(interval);
}, [cryptoPrices]);

const toggleCryptoDetails = (id) => {
  setExpandedCrypto(expandedCrypto === id ? null : id);
};

  const cryptoData = [
    { id: 'bitcoin', name: 'Bitcoin (BTC)', icon: 'icons/bitcoin.png' },
    { id: 'ethereum', name: 'Ethereum (ETH)', icon: 'icons/ethereum.png' },
    { id: 'tether', name: 'Tether (USDT)', icon: 'icons/tether.png' },
    { id: 'binancecoin', name: 'BNB (BNB)', icon: 'icons/binancecoin.png' },
    { id: 'solana', name: 'Solana (SOL)', icon: 'icons/solana.png' },
    { id: 'usd-coin', name: 'USD Coin (USDC)', icon: 'icons/usd-coin.png' },
    { id: 'xrp', name: 'XRP (XRP)', icon: 'icons/xrp.png' },
    { id: 'toncoin', name: 'Toncoin (TONCOIN)', icon: 'icons/toncoin.png' },
    { id: 'dogecoin', name: 'Dogecoin (DOGE)', icon: 'icons/dogecoin.png' },
    { id: 'cardano', name: 'Cardano (ADA)', icon: 'icons/cardano.png' },
    { id: 'tron', name: 'TRON (TRX)', icon: 'icons/tron.png' },
    { id: 'wrapped-bitcoin', name: 'Wrapped Bitcoin (WBTC)', icon: 'icons/wrapped-bitcoin.png' },
    { id: 'shiba-inu', name: 'Shiba Inu (SHIB)', icon: 'icons/shiba-inu.png' },
    { id: 'avalanche-2', name: 'Avalanche (AVAX)', icon: 'icons/avalanche-2.png' },
    { id: 'polkadot', name: 'Polkadot (DOT)', icon: 'icons/polkadot.png' },
    { id: 'chainlink', name: 'Chainlink (LINK)', icon: 'icons/chainlink.png' },
    { id: 'bitcoin-cash', name: 'Bitcoin Cash (BCH)', icon: 'icons/bitcoin-cash.png' },
    { id: 'near', name: 'NEAR Protocol (NEAR)', icon: 'icons/near.png' },
    { id: 'uniswap', name: 'Uniswap (UNI)', icon: 'icons/uniswap.png' },
    { id: 'polygon', name: 'Polygon (MATIC)', icon: 'icons/polygon.png' },
    { id: 'litecoin', name: 'Litecoin (LTC)', icon: 'icons/litecoin.png' },
    { id: 'dai', name: 'Dai (DAI)', icon: 'icons/dai.png' },
    { id: 'leo-token', name: 'LEO Token (LEO)', icon: 'icons/leo-token.png' },
    { id: 'pepe', name: 'Pepe (PEPE)', icon: 'icons/pepe.png' },
    { id: 'kaspa', name: 'Kaspa (KAS)', icon: 'icons/kaspa.png' },
    { id: 'internet-computer', name: 'Internet Computer (ICP)', icon: 'icons/internet-computer.png' },
    { id: 'ethereum-classic', name: 'Ethereum Classic (ETC)', icon: 'icons/ethereum-classic.png' },
    { id: 'aptos', name: 'Aptos (APT)', icon: 'icons/aptos.png' },
    { id: 'monero', name: 'Monero (XMR)', icon: 'icons/monero.png' },
    { id: 'hedera', name: 'Hedera (HBAR)', icon: 'icons/hedera.png' },
    { id: 'render-token', name: 'Render (RNDR)', icon: 'icons/render-token.png' },
    { id: 'cosmos', name: 'Cosmos (ATOM)', icon: 'icons/cosmos.png' },
    { id: 'stellar', name: 'Stellar (XLM)', icon: 'icons/stellar.png' },
    { id: 'mantle', name: 'Mantle (MNT)', icon: 'icons/mantle.png' },
    { id: 'first-digital-usd', name: 'First Digital USD (FDUSD)', icon: 'icons/first-digital-usd.png' },
    { id: 'okb', name: 'OKB (OKB)', icon: 'icons/okb.png' },
    { id: 'fetch-ai', name: 'Fetch.ai (FET)', icon: 'icons/fetch-ai.png' },
    { id: 'immutable-x', name: 'ImmutableX (IMX)', icon: 'icons/immutable-x.png' },
    { id: 'pancakeswap-token', name: 'PancakeSwap (CAKE)', icon: 'icons/pancakeswap-token.png' },
    { id: 'eth-2-staking-by-pooltogether', name: 'Eth 2.0 Staking (PETH)', icon: 'icons/eth-2-staking-by-pooltogether.png' },
    { id: 'binance-usd', name: 'Binance USD (BUSD)', icon: 'icons/binance-usd.png' },
    { id: 'usde', name: 'USDE (USDE)', icon: 'icons/usde.png' },
    { id: 'aave', name: 'Aave (AAVE)', icon: 'icons/aave.png' },
    { id: 'elrond-erd-2', name: 'MultiversX (EGLD)', icon: 'icons/elrond-erd-2.png' },
    { id: 'helium', name: 'Helium (HNT)', icon: 'icons/helium.png' },
    { id: 'chiliz', name: 'Chiliz (CHZ)', icon: 'icons/chiliz.png' },
    { id: 'quant-network', name: 'Quant (QNT)', icon: 'icons/quant-network.png' },
    { id: 'celo', name: 'Celo (CELO)', icon: 'icons/celo.png' },
    { id: 'curve-dao-token', name: 'Curve DAO (CRV)', icon: 'icons/curve-dao-token.png' },
    { id: 'theta-token', name: 'Theta Network (THETA)', icon: 'icons/theta-token&vs_currencies=usd.png' }
  ];

  return (
  <div className="crypto-market app">
    <a onClick={goBack}>
      <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '20px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
    </a>
    <h2 style={{ marginTop: '30px', fontSize: '30px'}}>Crypto Market</h2>
    <div className="market-summary">
      <p>Total Market Cap: <br/><span>${totalMarketCap ? totalMarketCap.toLocaleString() : 'Loading...'}</span></p>
      <p>Total 24hr Trading Volume: <br/><span>${totalVolume24h ? totalVolume24h.toLocaleString() : 'Loading...'}</span></p>
    </div>
    <main style={{ paddingTop: '20px', width: '90%', height: '500px', overflowY: 'auto', margin: '0 auto', paddingBottom: '100px' }}>
      
      <div className="crypto-prices">
        {cryptoData.map((crypto) => {
          const price = cryptoPrices[crypto.id]?.usd;
          const percentageChange = percentageChanges[crypto.id];

          if (price !== undefined) {
            return (
              <div className="crypto" key={crypto.id} onClick={() => toggleCryptoDetails(crypto.id)}>
                <div className="crypto-title">
                <div className="crypto-name">
                  <img src={crypto.icon} alt={`${crypto.name} icon`} width="25" height="25" />
                  {crypto.name}
                </div>
                <div className="crypto-price">
                  <span className={`price ${highlightedPrices[crypto.id] ? 'price-change-highlight' : ''}`}>
                    ${price.toFixed(2)}
                  </span>
                  {percentageChange !== undefined && (
                    <span className={`percentage-change ${percentageChange > 0 ? 'positive' : 'negative'}`}>
                      {percentageChange.toFixed(2)}%
                    </span>
                  )}
                </div>
                </div>

                {expandedCrypto === crypto.id && (
                  <div className="crypto-details">
                    <p><span className="detail-title">Market Cap:</span><span>${cryptoDetails[crypto.id]?.marketCap?.toLocaleString() || 'N/A'}</span></p>
                    <p><span className="detail-title">24hr Volume:</span><span>${cryptoDetails[crypto.id]?.volume24h?.toLocaleString() || 'N/A'}</span></p>
                  </div>
                )}
              </div>
            );
          }
          return null;
        })}
      </div>
    </main>
  </div>
);
}