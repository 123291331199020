
import BottomNav from './BottomNav';
import { useState } from 'react';
import './Login.css'; 
import { useNavigate, Routes, Route } from 'react-router-dom';

export default function More() {
  const [activeItem, setActiveItem] = useState('more');
  const navigate = useNavigate();
  
  const contestPage = () => {
    navigate('/contest');
  };
  
  const trybePage = () => {
    navigate('/trybe');
  };
  
  const cryptoMarketPage = () => {
    navigate('/crypto-market');
  };
  
  const wheelOfFortunePage = () => {
    navigate('/wheel-of-fortune');
  };

  return (
    <div className="more app">
      <h1 style={{ marginTop: '25px', fontSize: '30px'}}>More</h1>
      <div className="more-body">
        <a onClick={contestPage}><img src="new/referralcontest.png" alt="banner"/></a>
        <a onClick={trybePage} alt="banner"><img src="new/trybe.png"/></a>
        <a onClick={cryptoMarketPage} alt="banner"><img src="new/cryptomarket.png"/></a>
        <a onClick={wheelOfFortunePage} alt="banner"><img src="new/wheeloffortune.png"/></a>
      </div>
      <BottomNav activeItem={activeItem} setActiveItem={setActiveItem} />
    </div>
  );
}
