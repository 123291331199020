import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Profile.css';

export default function Profile() {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();
    
    const goBack = () => {
    navigate('/home');
  };

    useEffect(() => {
        const fetchUserData = async () => {
            const username = localStorage.getItem('username');
            if (!username) {
                console.error('Username not found in local storage');
                return;
            }
            try {
                const response = await fetch(`https://softcoin-axlm.onrender.com/api/profile/${username}`);
                if (!response.ok) {
                    throw new Error('Failed to fetch user data');
                }
                const data = await response.json();
                setUser(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchUserData();
    }, []);

    const handleSignOut = () => {
        // Clear both local storage and session storage
        localStorage.clear();
        sessionStorage.clear();
    
        // Optionally, you can also send a request to the server to invalidate the session if you're using server-side sessions
    
        // Navigate to login page
        navigate('/login');
    };
    

    const handleChangeWallet = () => {
        navigate('/connect-wallet');
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (error) {
        return <div>Error: {error}</div>;
    }

    return (
        <div className="profile">
            <a onClick={goBack}>
                <i className="fas fa-arrow-left" style={{ color: 'white', position: 'fixed', left: '18px', top: '40px', zIndex: '500', fontSize: '20px' }}></i>
            </a>
            <h2 style={{ marginTop: '20px', fontSize: '30px'}}>Profile</h2>
            {user ? (
                <div className="profile-info">
                    <p><strong>Full Name:</strong><span>{user.fullName}</span></p>
                    <p><strong>Username:</strong><span>{user.username}</span></p>
                    <p><strong>Email:</strong><span>{user.email}</span></p>
                    <p><strong>Mining Level:</strong><span>Level {user.level}</span></p>
                    <p><strong>Coin Balance:</strong><span style={{color: '#48e4fa', fontWeight: 'bold'}}>{user.coinBalance.toLocaleString(undefined, { maximumFractionDigits: 0 })} SFT</span></p>
                    <p><strong>Earnings:</strong><span style={{color: 'gold', fontWeight: 'bold'}}>${user.earningBalance.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                    <p><strong>Referrals:</strong><span>{user.referralCount}</span></p>
                    <p><strong>No. of Keys Collected:</strong><span>{user.key}</span></p>
                    <p><strong>Trybe Earnings:</strong><span>${user.trybeEarnings.toLocaleString(undefined, { maximumFractionDigits: 2 })}</span></p>
                    <p><strong>Commitment:</strong><span>${user.commitmentBalance}</span></p>
                    <p style={{fontSize: '12px'}}><strong>Wallet Address:</strong><span>{user.walletAddress || "Not Connected"}</span></p>
                    <p><strong>Total Withdrawal:</strong><span>${user.totalWithdrawal}</span></p>
                </div>
            ) : (
                <p>No user data found.</p>
            )}
            <button 
                style={{marginTop: '60px'}} 
                onClick={handleChangeWallet} 
                className="submit-button"
            >
                {user.walletAddress ? 'Change Wallet Address' : 'Connect Wallet Address'}
            </button>
            <button onClick={handleSignOut} className="submit-button">Sign Out</button>
        </div>
    );
}
